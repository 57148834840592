import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import HighlightCards from 'components/content-blocks/highlight';
import ImageGroup from 'components/content-blocks/image-group';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import Link from 'components/link/link';

import './styles/about.scss';

export default function About() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "about" } }) {
        edges {
          node {
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                copy
                title
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
              }
              cards_1 {
                header {
                  title
                }
                card {
                  image {
                    source_url
                  }
                  title
                }
              }
              cards_2 {
                header {
                  title
                }
                card {
                  image {
                    source_url
                  }
                  title
                  copy
                }
              }
              video_block_1 {
                video_id
              }

              image_group {
                title
                sub_title
                copy
                link {
                  url
                  text
                }
                images {
                  image {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          leader {
            copy
            title
            leader {
              head_shot {
                source_url
              }
              name
              title
              link
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const highlightCards1 = pageContext.cards_1;
  const highlightCards2 = pageContext.cards_2;
  const leadership = data.wordpressAcfOptions.options.leader;
  const imageGroup = pageContext.image_group;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="about-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup
          { ...hero }
          className="hero intercom-launch-demo"
          data-section="aboutHero"
        />
        <section className="background-section">
          <HighlightCards
            className="served has-text-centered"
            hasImage
            hasTitle
            { ...highlightCards1 }
          />
        </section>
       
        <HighlightCards
          className="core-values has-text-centered"
          hasImage
          hasTitle
          hasCopy
          hasHeader
          { ...highlightCards2 }
        />

        <section className="section has-text-centered leadership">
          <div className="container">
            <h2 className="title">{leadership.title}</h2>
            <p className="copy">{leadership.copy}</p>
            <div className="leadership-team columns">
              {leadership.leader.map((leader, index) => (
                <Link
                  key={ index }
                  to={ leader.link }
                  className="column is-half-mobile is-one-third-tablet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure className="leadership-image">
                    <img
                      loading="lazy"
                      src={ leader.head_shot.source_url }
                      alt={ `When I Work Leader ${leader.name} ${leader.title}` }
                      width="120px"
                    />
                  </figure>

                  <h3>
                    <strong>{leader.name}</strong>
                  </h3>
                  <h4>{leader.title}</h4>
                </Link>
              ))}
            </div>
          </div>
        </section>

        <ImageGroup className="press-room" { ...imageGroup[0] } />
        <ImageGroup className="careers" { ...imageGroup[1] } />
      </div>
    </Layout>
  );
}
