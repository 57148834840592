import Link from 'components/link/link';

import './styles/image-group.scss';

type ImageGroupProps = {
  cards: [],
  className: string,
  copy: string
  images: [], 
  link: {
    text: string,
    url: string
  },
  title: string
}
type ImagesArg = {
  image: {
    source_url: string
  }
}
const ImageGroup = ({ className, title, copy, cards, images, link }:ImageGroupProps) => (
  <section
    className={ `image-group-section section image-group has-text-centered ${className || ''}` }
  >
    <div className="container">
      <h2 className="title">{title}</h2>
      <p className="copy">{copy}</p>
      <div className="image-group-images">
        {!!cards ? (
          <div className="columns">
            {images.map((images: ImagesArg, uniqueId) => (
              <div key={ uniqueId } className={ `column ${cards}` }>
                <div className="card">
                  <img src={ images.image.source_url } alt="Various Images" aria-hidden="true" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          images.map((images: ImagesArg, internalImage) => (
            <figure
              key={ internalImage }
              className={ `image-container image-${internalImage}` }
            >
              <img
                loading="lazy"
                src={ images.image.source_url }
                alt="fill me out"
              />
            </figure>
          ))
        )}
      </div>
      {!!link && link.url !== '' ? (
        <Link
          className="button is-rounded image-group-link"
          to={ link.url }
        >
          {link.text}
        </Link>
      ) : null}
    </div>
  </section>
);

export default ImageGroup;
